import {ENVIRONMENT_INITIALIZER, inject, Provider} from '@angular/core';
import {Store} from '@softline/core';
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore} from '@softline/ui-core';
import {ApplicationStore, SOFTLINE_FEATURE_APPLICATIONS} from '@softline/application';
import {SOFTLINE_PERMISSION_WERKSTAETTENAUFTRAG} from './auftrag.permissions';
import {default as de} from '../../i18n/de.json'
import {SOFTLINE_CONFIG_WERKSTAETTENAUFTRAG} from './auftrag.shared';
import { ChecklistenService } from '../../../../allgemein/checkliste/src/lib/services/checklisten.service';

export const auftragProviders: Provider[] = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    multi: true,
    useValue: () => {
      const store = inject(Store);
      store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
        module: 'werkstaettenauftrag',
        language: 'de',
        translations: de
      });
      store.commit(SOFTLINE_FEATURE_APPLICATIONS, ApplicationStore.mutations.add, {
        name: '#WERKSTAETTENAUFTRAG.APP',
        icon: 'fa-regular fa-garage',
        route: '/werkstaettenauftrag',
        permission: SOFTLINE_PERMISSION_WERKSTAETTENAUFTRAG
      });
    }
  },
  {
    provide: SOFTLINE_CONFIG_WERKSTAETTENAUFTRAG,
    useValue: {
      aufgabeName: 'Warenkorb Werkstatt'
    }
  }
]
