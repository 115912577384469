import {Routes} from '@angular/router';
import {AuthenticationGuard, AuthorizationGuard} from '@softline/auth';
import {ENVIRONMENT_INITIALIZER, importProvidersFrom, inject} from '@angular/core';
import {SOFTLINE_PERMISSION_WERKSTAETTENAUFTRAG} from './auftrag.permissions';
import {
  BULK_ENTITY_SERVICE,
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SOFTLINE_STORE_TEMPLATE_SERVICE,
  SoftlineEntityService,
  SoftlineTemplateService, Store,
  StoreModule
} from '@softline/core';
import {
  SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
  SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARTIKEL,
  SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE
} from './auftrag.shared';

import {
  SOFTLINE_API_WERKSTAETTENAUFTRAG_TASK_ITEM_RESOURCE_PATH,
  SOFTLINE_API_WERKSTAETTENAUFTRAG_TASK_ITEM_TEMPLATE_RESOURCE_PATH,
  SOFTLINE_API_WERKSTAETTENAUFTRAG_TASK_RESOURCE_PATH,
  SOFTLINE_API_WERKSTAETTENAUFTRAG_TASK_TEMPLATE_RESOURCE_PATH,
  SOFTLINE_API_WERKSTAETTENAUFTRAG_WORK_REPORT_RESOURCE_PATH
} from './auftrag.api';
import {WerkstattArbeitsberichtStore} from './store/werkstatt-arbeitsbericht.store';
import * as WerkstattAuftraegeStore from './store/werkstatt-auftraege.store';
import {WerkstattAuftragArtikelStore} from './store/werkstatt-auftrag-artikel.store';
import {ArbeitsberichtService} from './services/arbeitsbericht.service';
import {SignatureService} from './services/signature.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ArtikelScanService} from './services/artikel-scan.service';
import {BulkAuftragArtikelService} from './services/bulk-auftrag-artikel.service';
import {BulkArbeitsberichtService} from './services/bulk-arbeitsbericht.service';
import { ChecklistenService } from '../../../../allgemein/checkliste/src/lib/services/checklisten.service';

export const auftragRoutes: Routes = [
  {
    path: 'werkstaettenauftrag',
    loadComponent: () => import('./pages/auftrag.component'),
    children: [
      {
        path: 'erstellen',
        loadComponent: () => import('./pages/erstellen/erstellen.component').then(o => o.ErstellenComponent),
      },
      {
        path: ':id',
        loadComponent: () => import('./pages/auftrag/auftrag.component').then(o => o.AuftragComponent),
      },
      {
        path: ':id/artikel',
        loadComponent: () => import('./pages/artikel-hinzufuegen/artikel-hinzufuegen.component').then(o => o.ArtikelHinzufuegenComponent),
      },
      {
        path: ':id/arbeitsbericht',
        loadComponent: () => import('./pages/arbeitsbericht/arbeitsbericht.component').then(o => o.ArbeitsberichtComponent),
      },
      {
        path: '',
        loadComponent: () => import('./pages/list/list.component').then(o => o.ListComponent),
      },
      {
        path: '**',
        redirectTo: ''
      },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: {
      permission: SOFTLINE_PERMISSION_WERKSTAETTENAUFTRAG,
    },
    providers: [
      {
        provide: SignatureService,
        useFactory: () => {
          const service = inject(ConnectionHttpService)
          const sanitizer = inject(DomSanitizer)
          return new SignatureService(service, sanitizer)
        }
      },
      {
        provide: ArtikelScanService,
        useFactory: () => {
          const service = inject(ConnectionHttpService)
          return new ArtikelScanService(service)
        }
      },
      importProvidersFrom(
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
          feature: WerkstattArbeitsberichtStore.feature,
          providers: [
            {
              provide: SOFTLINE_STORE_ENTITY_SERVICE,
              useFactory: () => new SoftlineEntityService(
                inject(SOFTLINE_SERVICE_HTTP),
                SOFTLINE_API_WERKSTAETTENAUFTRAG_WORK_REPORT_RESOURCE_PATH
              )
            },
            {
              provide: ArbeitsberichtService,
              useClass: ArbeitsberichtService
            },
            {
              provide: BULK_ENTITY_SERVICE,
              useFactory: () => new BulkArbeitsberichtService(inject(ConnectionHttpService))
            },
          ]
        }),
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARTIKEL,
          feature: WerkstattAuftragArtikelStore.feature,
          providers: [
            {
              provide: SOFTLINE_STORE_ENTITY_SERVICE,
              useFactory: () => new SoftlineEntityService(
                inject(SOFTLINE_SERVICE_HTTP),
                SOFTLINE_API_WERKSTAETTENAUFTRAG_TASK_ITEM_RESOURCE_PATH
              )
            },
            {
              provide: SOFTLINE_STORE_TEMPLATE_SERVICE,
              useFactory: () => new SoftlineTemplateService(
                inject(SOFTLINE_SERVICE_HTTP),
                SOFTLINE_API_WERKSTAETTENAUFTRAG_TASK_ITEM_TEMPLATE_RESOURCE_PATH
              )
            },
            {
              provide: BULK_ENTITY_SERVICE,
              useFactory: () => new BulkAuftragArtikelService(inject(ConnectionHttpService))
            },
          ]
        }),
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
          feature: WerkstattAuftraegeStore.feature,
          providers: [
            {
              provide: SOFTLINE_STORE_ENTITY_SERVICE,
              useFactory: () => new SoftlineEntityService(
                inject(SOFTLINE_SERVICE_HTTP),
                SOFTLINE_API_WERKSTAETTENAUFTRAG_TASK_RESOURCE_PATH
              )
            },
            {
              provide: SOFTLINE_STORE_TEMPLATE_SERVICE,
              useFactory: () => new SoftlineTemplateService(
                inject(SOFTLINE_SERVICE_HTTP),
                SOFTLINE_API_WERKSTAETTENAUFTRAG_TASK_TEMPLATE_RESOURCE_PATH
              )
            },
          ]
        })
      ),
      {
        provide: ENVIRONMENT_INITIALIZER,
        multi: true,
        useValue: async () => {
          const store = inject(Store);
          console.log('loading active...')
          await store.dispatch(
            SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
            WerkstattArbeitsberichtStore.actions.loadActive
          )
        }
      },
    ],
  }
]
