import {createAction, createGetter, createMutation, CustomStore, mutate, on, select} from "@softline/core";
import {Beleg} from '../data/beleg';

interface State {
  belege: Beleg[];
  bezugsnummer?: string;
  belegdatum?: string;
  lieferant: {
    id: number;
    nummer: number;
    kurzbez: string;
  } | null;
}

export const getters = {
  belege: createGetter<State, Beleg[]>('mdeWarenverprobungBelegeStore/getBelege'),
  lieferant: createGetter<State, State['lieferant']>('mdeWarenverprobungBelegeStore/getLieferant'),
  belegdatum: createGetter<State, string | null>('mdeWarenverprobungBelegeStore/getBelegdatum'),
  bezugsnummer: createGetter<State, State['bezugsnummer']>('mdeWarenverprobungBelegeStore/getBezugsnummer'),
  isValidBeleg: createGetter<State, boolean, Beleg>('mdeWarenverprobungBelegeStore/isValidBeleg'),
}

export const mutations = {
  addBeleg: createMutation<State, Beleg>('mdeWarenverprobungBelegeStore/addBeleg'),
  setLieferant: createMutation<State, State['lieferant']>('mdeWarenverprobungBelegeStore/setLieferant'),
  setBelegdatum: createMutation<State, string | null>('mdeWarenverprobungBelegeStore/setBelegdatum'),
  setBezugsnummer: createMutation<State, State['bezugsnummer']>('mdeWarenverprobungBelegeStore/setBezugsnummer'),
  clear: createMutation<State, void>('mdeWarenverprobungBelegeStore/clear')
}

export const actions = {
  addBeleg: createAction<State, Beleg, Beleg | null>('mdeWarenverprobungBelegeStore/addBeleg'),
}

export const WarenverprobungBelegeStore: CustomStore<State, typeof mutations, typeof getters, typeof actions> = {
  getters,
  mutations,
  actions,
  feature: {
    initialState: {
      belege: [],
      lieferant: null
    },
    getters: [
      select(getters.belege, ({ state }) => state.belege),
      select(getters.lieferant, ({ state }) => state.lieferant),
      select(getters.belegdatum, ({ state }) => state?.belegdatum),
      select(getters.bezugsnummer, ({ state }) => state?.bezugsnummer),
      select(getters.isValidBeleg, ({ state, params }) =>
        !state?.lieferant?.id ? true : state?.lieferant?.id === params.abbuchenBelegBean.lieferant?.id
      ),
    ],
    mutations: [
      mutate(mutations.clear, () => ({ belege: [], lieferant: null, bezugsnummer: undefined, belegdatum: undefined })),
      mutate(mutations.setLieferant, ({ state, params }) => ({ ...state, lieferant: params })),
      mutate(mutations.setBezugsnummer, ({ state, params }) => ({ ...state, bezugsnummer: params })),
      mutate(mutations.setBelegdatum, ({ state, params }) => ({ ...state, belegdatum: params ?? undefined })),
      mutate(mutations.addBeleg, ({ state, params }) => {
        if (state.belege.find(o => o.abbuchenBelegBean.id === params.abbuchenBelegBean.id))
          return state;

        return {
          ...state,
          belege: [...state.belege, params]
        }
      })
    ],
    actions: [
      on(actions.addBeleg, ({ state , get, params, featureName, commit }) => {
        const lieferant = get(featureName, getters.lieferant);
        const bezugsnummer = get(featureName, getters.bezugsnummer);

        if (!lieferant)
          commit(featureName, mutations.setLieferant, params.abbuchenBelegBean.lieferant);

        if (!bezugsnummer && params?.abbuchenBelegBean.bezugsnummer)
          commit(featureName, mutations.setBezugsnummer, params.abbuchenBelegBean.bezugsnummer);

        if (!bezugsnummer && params?.abbuchenBelegBean.belegdatum)
          commit(featureName, mutations.setBelegdatum, params?.abbuchenBelegBean.belegdatum);

        if (params?.abbuchenBelegBean.lieferant?.id !== get(featureName, getters.lieferant)?.id)
          return null;

        commit(featureName, mutations.addBeleg, params);

        return params;
      })
    ]
  }
}
