import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { equals } from '@softline/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'soft-toggle-switch',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleSwitchComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleSwitchComponent implements OnInit, ControlValueAccessor {
  @Input()
  set value(value: any) {
    this.setValue(value);
  }
  get value(): any {
    return this._value;
  }

  @Input()
  set checked(checked: any) {
    this.setChecked(checked);
  }
  get checked(): any {
    return this._checked;
  }

  @Input() trueValue: any = true;
  @Input() falseValue: any = false;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() size: 'default' |'large' = 'default';

  @Output() valueChange = new EventEmitter<any>();
  @Output() checkedChange = new EventEmitter<boolean>();

  private _checked = false;
  private _value: any;

  // tslint:disable-next-line:no-empty
  private onChange = (value: any) => {};
  // tslint:disable-next-line:no-empty
  private onTouch = () => {};

  ngOnInit(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (this.disabled) return;

    if (obj === this.trueValue || obj === this.falseValue) {
      this._value = obj;
      this._checked = equals(this._value, this.trueValue);
      return;
    }
  }

  toggle(): void {
    if (this.disabled) return;

    this.setChecked(!this._checked);
  }

  private setValue(value: any): void {
    if (value !== this.trueValue && value !== this.falseValue) return;

    value === this.trueValue ? (this._checked = true) : (this._checked = false);
    this._value = value;

    this.writeValue(value);
    this.onChange(value);
    this.onTouch();
  }

  private setChecked(checked: boolean): void {
    const previousChecked = this._checked;

    if (checked === previousChecked) return;

    this._checked = checked;
    this.setValue(this._checked ? this.trueValue : this.falseValue);
    this.emitEvents(this._checked);
  }

  private emitEvents(checked: boolean): void {
    this.checkedChange.emit(checked);
    this.valueChange.emit(checked ? this.trueValue : this.falseValue);
  }
}
